import { Select, SelectItem } from 'components/core/select';
import { getItemKey } from 'modules/wixi/stores/WixiPageStore';
import styles from './index.module.css';

import Button from 'components/core/button';
import WarningText from 'components/core/warning-text';
import ConnectWalletButton from 'components/core/connect-wallet-button';

import LoadingWrapper from './loading-wrapper';
import TransactionResult from './transaction-result';

import { useIntl } from 'react-intl';
import { ROUTES } from 'routes';
import { Link } from 'react-router-dom';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useWixiPageStore } from 'modules/wixi/provider/WixiPageStoreProvider';
import { useWalletStore } from 'providers/BaseStoresProvider';
import { cutString } from 'utils/strings';

const WixiPurchaseForm = observer(() => {
  const [type, setType] = useState<string>();

  const intl = useIntl();
  const walletStore = useWalletStore();
  const wixiPageStore = useWixiPageStore();

  const types = useMemo(() => {
    const items: SelectItem[] = [];

    for (const key of wixiPageStore.dataByType.keys()) {
      const first = wixiPageStore.dataByType.get(key)![0];
      items.push({
        value: first.typePaymentCode,
        label: first.typePayment,
      });
    }

    return items;
  }, [wixiPageStore.dataByType]);

  const methods = useMemo(() => {
    const items: SelectItem[] = [];

    if (type) {
      const data = wixiPageStore.dataByType.get(type) ?? [];
      for (const item of data) {
        items.push({
          value: item.methodPaymentCode,
          label: item.methodPayment,
          key: getItemKey(item),
        });
      }
    }

    return items;
  }, [type, wixiPageStore.dataByType]);

  const onSummChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      wixiPageStore.onSummChange(event.target.value),
    [wixiPageStore]
  );

  const onFromNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      wixiPageStore.onFromNameChange(event.target.value),
    [wixiPageStore]
  );

  const onTypeSelect = useCallback((value: string) => {
    setType(value);
    wixiPageStore.selectAddData(
      wixiPageStore.dataByType.get(value)?.at(0) ?? null
    );
  }, [wixiPageStore]);

  const onMethodSelect = useCallback((value: string) => {
    const data = wixiPageStore.state.supportedAddData.find(
      (item) => item.methodPaymentCode === value,
    ) ?? null;
    wixiPageStore.selectAddData(data);
  }, [wixiPageStore]);

  useEffect(() => {
    const item = wixiPageStore.state.supportedAddData.at(0);

    if (item) {
      setType(item.typePaymentCode);
      wixiPageStore.selectAddData(item);
    }
  }, [wixiPageStore.state.supportedAddData]); // eslint-disable-line

  useEffect(() => {
    if (wixiPageStore.state.redirect) {
      window.location.replace(wixiPageStore.state.redirect);
    }
  }, [wixiPageStore.state.redirect])

  if (wixiPageStore.state.initResult.inProgress) {
    return <LoadingWrapper />;
  }

  if (wixiPageStore.state.initResult.error) {
    return (
      <TransactionResult
        success={false}
        text={intl.formatMessage({
          id: 'wixi_page.error.init',
          defaultMessage:
            'Error occured during form initialization. Please try again later.',
        })}
      />
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.row}>
        <Select
          value={type}
          items={types}
          onSelect={onTypeSelect}
        />
        <Select
          value={wixiPageStore.state.selectedAddData?.methodPaymentCode}
          items={methods}
          onSelect={onMethodSelect}
        />
      </div>

      <div className={styles.value_container}>
        <span className={styles.label}>
          {intl.formatMessage({
            id: 'wixi_page.from_name',
            defaultMessage: 'Payer',
          })}
        </span>

        <input
          placeholder={intl.formatMessage({
            id: 'wixi_page.from_name_placeholder',
            defaultMessage: 'Enter payer name',
          })}
          className={styles.value_input}
          value={wixiPageStore.state.inputValues.fromName}
          onChange={onFromNameChange}
        />
      </div>

      <div className={styles.value_container}>
        <span className={styles.label}>
          {intl.formatMessage({
            id: 'wixi_page.you_spend',
            defaultMessage: 'You spend',
          })}
        </span>

        <div className={styles.input_with_select}>
          <input
            placeholder='0'
            className={styles.value_input}
            value={wixiPageStore.state.inputValues.summ}
            onChange={onSummChange}
          />

          {wixiPageStore.state.selectedAddData?.currency}
        </div>
      </div>

      {wixiPageStore.state.selectedAddData && (
        <div className={styles.hint}>
          {intl.formatMessage({
            id: 'wixi_page.min_max',
            defaultMessage: '{min} - {max} {symbol}',
          }, {
            min: wixiPageStore.state.selectedAddData.minSumm,
            max: wixiPageStore.state.selectedAddData.maxSumm,
            symbol: wixiPageStore.state.selectedAddData.currency,
          })}
        </div>
      )}

      {wixiPageStore.state.validationError.show && (
        <div className={styles.validation_error}>
          {wixiPageStore.state.validationError.message}
        </div>
      )}

      {walletStore.account && (
        <div className={styles.recipient}>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'wixi_page.recipient',
              defaultMessage: 'Recipient',
            })}
          </span>

          <span className={styles.address}>
            {cutString(walletStore.account.address, 8, 6)}
          </span>
        </div>
      )}

      <div className={styles.warning}>
        {walletStore.account ? (
          <WarningText
            text={intl.formatMessage({
              id: 'wixi_page.recipient.warning',
              defaultMessage:
                'Please make sure that the recipient address is correct',
            })}
          />
        ) : (
          <WarningText
            text={intl.formatMessage({
              id: 'wixi_page.wallet_not_connected.warning',
              defaultMessage: 'Please connect EVER Wallet to continue',
            })}
          />
        )}
      </div>

      <div className={styles.actions}>
        {walletStore.account ? (
          <Button
            variant='primary'
            showSpinner={wixiPageStore.state.loading}
            disabled={wixiPageStore.disabled}
            onClick={wixiPageStore.onSubmit}
          >
            {intl.formatMessage({
              id: 'wixi_page.exchange',
              defaultMessage: 'Exchange',
            })}
          </Button>
        ) : (
          <ConnectWalletButton />
        )}

        <Link to={ROUTES.index.card.fullPath}>
          <Button variant='secondary'>
            {intl.formatMessage({
              id: 'wixi_page.back',
              defaultMessage: 'Back',
            })}
          </Button>
        </Link>
      </div>
    </div>
  );
});

export default WixiPurchaseForm;
