import styles from './index.module.css';
import classNames from 'classnames';

import { ReactComponent as ArrowDown } from 'assets/images/arrow-down.svg';

import { Menu, MenuItem } from '@szhsin/react-menu';

import { ReactNode, useMemo, Key } from 'react';

export interface SelectItem {
  key?: Key;
  value: string;
  label: ReactNode;
}

interface Props {
  value: string | null | undefined;
  items: SelectItem[];
  onSelect(value: string): void;
}

export const Select = ({ value, items, onSelect }: Props) => {
  const selected = useMemo(() => {
    if (!value) return undefined;
    return items.find((item) => item.value === value);
  }, [value, items]);

  return (
    <Menu
      menuButton={({ open }) => (
        <div className={styles.btn_container}>
          <span className={styles.label}>{selected?.label}</span>

          <ArrowDown
            className={classNames(styles.btn_container_arrow, {
              [styles.open]: open === true,
            })}
          />
        </div>
      )}
      menuClassName={styles.menu}
      align='center'
      overflow='auto'
      boundingBoxPadding='24px 12px'
      gap={10}
    >
      {items.map((item, index) => (
        <MenuItem
          key={item.key ?? index}
          className={classNames(styles.menu_item, {
            [styles.menu_item_selected]: item.value === value,
          })}
          onClick={() => onSelect(item.value)}
        >
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
}
