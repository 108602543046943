import axios from 'axios';

import simplexLogo from 'assets/images/simplex.png';
import wixiLogo from 'assets/images/wixi.svg';

import { ROUTES } from 'routes';
import { MANIFEST_URL } from 'assets/config';

import { makeAutoObservable, runInAction } from 'mobx';

export type AssetData = {
  title: string;
  icon: string;
  link: string;
  description?: string;
  walletsIcon?: string;
  networksIcon?: string;
  fastest?: boolean;
};

type Manifest = {
  dex_exchanges: AssetData[];
  cex_exchanges: AssetData[];
  cardPayments: AssetData[];
};

export class StaticDataStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _dexExchanges: AssetData[] = [];
  private _cexExchanges: AssetData[] = [];
  private _cardPayments: AssetData[] = [];

  private _simplex: AssetData = {
    title: 'Simplex',
    icon: simplexLogo,
    description: 'Min amount - $50, 5% fee\\nAvailable worldwide',
    link: ROUTES.simplex.fullPath,
    fastest: true,
  };

  private _wixi: AssetData = {
    title: 'Wixi',
    icon: wixiLogo,
    description: 'Min amount - 10$, fee 1-7%\\nExchange to USDT only',
    link: ROUTES.wixi.fullPath,
  };

  get dexExchanges() {
    return this._dexExchanges;
  }

  get cexExchanges() {
    return this._cexExchanges;
  }

  get cardPayments() {
    return this._cardPayments;
  }

  get simplex() {
    return this._simplex;
  }

  get wixi() {
    return this._wixi;
  }

  async init() {
    await this.getBuyEverAssets();
  }

  private async getBuyEverAssets() {
    const manifestData = await axios.get<Manifest>(MANIFEST_URL);
    const manifestJson = manifestData.data;

    runInAction(() => {
      this._dexExchanges = manifestJson.dex_exchanges;
      this._cexExchanges = manifestJson.cex_exchanges;
      this._cardPayments = manifestJson.cardPayments;
    });
  }
}
