import styles from './index.module.css';

import Button from 'components/core/button';

import { useIntl } from 'react-intl';
import { ROUTES } from 'routes';
import { Link } from 'react-router-dom';

import { ReactComponent as CheckSvg } from 'assets/images/check.svg';
import { ReactComponent as CrossSvg } from 'assets/images/cross.svg';

interface Props {
  success: boolean;
  text: string;
}

const TransactionResult = ({ success, text }: Props) => {
  const intl = useIntl();

  return (
    <div className={styles.main}>
      {success ? <CheckSvg /> : <CrossSvg />}

      <span>{text}</span>

      <div className={styles.actions}>
        <Link to={ROUTES.index.card.fullPath}>
          <Button variant='secondary'>
            {intl.formatMessage({
              id: 'wixi_page.back',
              defaultMessage: 'Back',
            })}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default TransactionResult;
