import WixiPurchaseForm from './purchase-form';
import PageContainer from 'components/layout/page-container';

import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { WixiPageStoreProvider } from 'modules/wixi/provider/WixiPageStoreProvider';

const WixiPage = observer(() => {
  const intl = useIntl();

  return (
    <WixiPageStoreProvider>
      <PageContainer
        title={intl.formatMessage({
          id: 'wixi_page.title',
          defaultMessage: 'Top up EVER Wallet',
        })}
      >
        <WixiPurchaseForm />
      </PageContainer>
    </WixiPageStoreProvider>
  );
});

export default WixiPage;
