import BigNumber from 'bignumber.js';
import { makeAutoObservable, runInAction } from 'mobx';
import type {
  WixiAddData,
  WixiAddPayAccountDetailsData,
  WixiAddPayAccountDetailsRequest,
  WixiError,
  WixiResponse,
} from 'modules/wixi/models';
import { WalletStore } from 'stores/WalletStore';
import { sanitizeNumberString } from 'utils/strings';

type State = {
  initResult: {
    inProgress: boolean;
    error: boolean;
  };
  loading: boolean;
  supportedAddData: WixiAddData[];
  selectedAddData: WixiAddData | null;
  inputValues: {
    summ: string;
    fromName: string;
  };
  validationError: {
    show: boolean;
    message: string;
  };
  redirect: string | null;
};

const DEFAULT_STATE: State = {
  initResult: {
    inProgress: true,
    error: false,
  },
  loading: false,
  supportedAddData: [],
  selectedAddData: null,
  inputValues: {
    summ: '',
    fromName: '',
  },
  validationError: {
    show: false,
    message: '',
  },
  redirect: null,
};

export class WixiPageStore {
  private _state: State = DEFAULT_STATE;

  constructor(
    private walletStore: WalletStore,
    private wixiGateUrl: string,
    private wixiApiUrl: string
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get state() {
    return this._state;
  }

  get disabled() {
    const {
      loading,
      selectedAddData,
      inputValues: { summ, fromName },
    } = this.state;

    if (selectedAddData) {
      const { minSumm, maxSumm } = selectedAddData;
      const amount = BigNumber(summ);

      if (amount.isNaN() || amount.lt(minSumm) || amount.gt(maxSumm)) {
        return true;
      }
    }

    return loading || !selectedAddData || !summ || !fromName;
  }

  get dataByType() {
    const map = new Map<string, WixiAddData[]>();

    for (const data of this.state.supportedAddData) {
      let items = map.get(data.typePaymentCode);

      if (!items) {
        items = [];
        map.set(data.typePaymentCode, items);
      }

      items.push(data);
    }

    return map;
  }

  async init() {
    try {
      const response = await this.fetchAddData();

      if (response.dataType === 'result') {
        // remove duplicated items from API response ¯\_(ツ)_/¯
        const keys = new Set<string>();
        const data = response.data.filter((item) => {
          const key = getItemKey(item);
          if (keys.has(key)) return false;
          keys.add(key);
          return true;
        });

        runInAction(() => {
          this._state.supportedAddData = data;
          this._state.selectedAddData = null;
          this._state.initResult = { inProgress: false, error: false };
        });
      } else {
        throw response.data;
      }
    } catch (error) {
      console.error(error);

      runInAction(() => {
        this._state.initResult = {
          inProgress: false,
          error: true,
        };
      });
    }
  }

  async onSubmit() {
    if (!this.walletStore.account) {
      throw new Error('Cannot exchange - wallet is not connected.');
    }
    if (!this.state.selectedAddData) {
      throw new Error('Cannot exchange - add data is not selected.');
    }

    try {
      this._state.loading = true;

      const { inputValues, selectedAddData } = this.state;
      const response = await this.fetchAddPayAccountDetails({
        summ: inputValues.summ,
        fromName: inputValues.fromName,
        currency: selectedAddData.currency,
        methodPaymentCode: selectedAddData.methodPaymentCode,
        typePaymentCode: selectedAddData.typePaymentCode,
        addressWithdraw: this.walletStore.account.address,
      });

      if (response.dataType === 'result') {
        runInAction(() => {
          this._state.redirect = `${this.wixiGateUrl}/en/pay?signature=${response.data.signature}`;
        });
      } else {
        throw response.data;
      }
    } catch (error: any) {
      console.error(error);

      runInAction(() => {
        this._state.loading = false;
        this._state.validationError = {
          show: true,
          message: isWixiError(error) ? error.msg : error.message,
        };
      });
    }
  }

  selectAddData(addData: WixiAddData | null) {
    this._state.selectedAddData = addData;
    this._state.inputValues = {
      summ: '',
      fromName: '',
    };
  }

  onSummChange(value: string) {
    this._state.inputValues.summ = sanitizeNumberString(value);
  }

  onFromNameChange(value: string) {
    this._state.inputValues.fromName = value;
  }

  private async fetchAddData(): Promise<WixiResponse<WixiAddData[]>> {
    const response = await fetch(`${this.wixiApiUrl}/api/getDataAdd`, {
      method: 'post'
    });

    return response.json();
  }

  private async fetchAddPayAccountDetails(
    data: WixiAddPayAccountDetailsRequest,
  ): Promise<WixiResponse<WixiAddPayAccountDetailsData>> {
    const response = await fetch(`${this.wixiApiUrl}/api/addPayAccountDetails`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return response.json();
  }
}

export function getItemKey(item: WixiAddData) {
  return `${item.typePaymentCode}_${item.methodPaymentCode}_${item.currency}`;
}

function isWixiError(value: any): value is WixiError {
  return value && 'msg' in value;
}
