import styles from './index.module.css';
import PaymentLink from '../payment-link';

import { observer } from 'mobx-react-lite';
import { useStaticDataStore } from 'providers/BaseStoresProvider';

const WithCard = observer(() => {
  const staticDataStore = useStaticDataStore();

  return (
    <div className={styles.main}>
      <div className={styles.items}>
        <PaymentLink data={staticDataStore.simplex} internal />
        <PaymentLink data={staticDataStore.wixi} internal />

        {staticDataStore.cardPayments.map((payment) => (
          <PaymentLink key={payment.title} data={payment} />
        ))}
      </div>
    </div>
  );
});

export default WithCard;
