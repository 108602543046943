import Body from './layout/body';
import Footer from './layout/footer';
import Header from './layout/header';
import Wrapper from './layout/wrapper';

import HomePage from 'modules/home';
import SimplexPage from 'modules/simplex';
import LegalPage from 'modules/legal';
import WixiPage from 'modules/wixi';

import { ROUTES } from 'routes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ProvideBaseStores } from 'providers/BaseStoresProvider';

const App = () => {
  return (
    <ProvideBaseStores>
      <Wrapper>
        <Header />

        <Body>
          <Routes>
            <Route path={ROUTES.index.path} element={<HomePage />} />
            <Route path={ROUTES.simplex.path} element={<SimplexPage />} />
            <Route path={ROUTES.wixi.path} element={<WixiPage />} />
            <Route path={ROUTES.legal.path} element={<LegalPage />} />
            <Route
              path='*'
              element={<Navigate to={ROUTES.index.fullPath} replace />}
            />
          </Routes>
        </Body>

        <Footer />
      </Wrapper>
    </ProvideBaseStores>
  );
};

export default App;
