import { WIXI_API_URL, WIXI_GATE_URL } from 'assets/config';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { WixiPageStore } from 'modules/wixi/stores/WixiPageStore';

import { useWalletStore } from 'providers/BaseStoresProvider';
import { createContext, useContext, useEffect } from 'react';

const storeContext = createContext<WixiPageStore>({} as WixiPageStore);

type Props = {
  children: React.ReactNode;
};
export const WixiPageStoreProvider = observer(({ children }: Props) => {
  const wallet = useWalletStore();
  const store = useLocalObservable(() => new WixiPageStore(wallet, WIXI_GATE_URL, WIXI_API_URL));

  useEffect(() => {
    store.init();
  }, [store]);

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const useWixiPageStore = () => {
  return useContext(storeContext);
};
